import { getUser } from "../auth/index"
import { isWebview } from "../browser/index"
import { concatStrings } from "../string/index"

interface EventAttributes {
	[key: string]: any
	userId?: string | null | number
	userAgent?: string
	kycStatus?: string
	isBondInvestor?: boolean
	isFDInvestor?: boolean
	isSGBInvestor?: boolean
	userEmail?: string
	userPhoneNumber?: number | string
}

const trackEventOnGTM = (
	eventName: string,
	eventAttributes?: EventAttributes,
) => {
	window.gtmDataLayer = window.gtmDataLayer || []
	const tempEvents = {
		userId: null,
		...eventAttributes,
	}

	if (isWebview()) {
		tempEvents.userAgent = "android"
	}

	const user = getUser()
	if (user) {
		if (user.userId) {
			tempEvents.userId = user.userId
		}

		if (user.kycStatus) {
			tempEvents.kycStatus = user.kycStatus
		}

		if (user.isBondsInvestor) {
			tempEvents.isBondInvestor = user.isBondsInvestor
		}

		if (user.isFDInvestor) {
			tempEvents.isFDInvestor = user.isFDInvestor
		}

		if (user.isSgbInvestor) {
			tempEvents.isSGBInvestor = user.isSgbInvestor
		}

		if (user.userEmail) {
			tempEvents.userEmail = user.userEmail
		}

		if (user.userPhoneNumber) {
			tempEvents.userPhoneNumber = concatStrings(
				"+91",
				user.userPhoneNumber.toString() || "",
			)
		}
	}

	window.gtmDataLayer.push({
		event: eventName,
		...tempEvents,
	})
}

export const trackEvent = (
	eventName: string,
	eventAttributes?: EventAttributes,
) => trackEventOnGTM(eventName, eventAttributes)
