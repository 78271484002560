import { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { setToSessionStorage } from "@common/utils/browser/index";
type UTMParams = {
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_term?: string | null;
};
const useStoreUTMQuery = () => {
  const searchParams = useSearchParams();
  useEffect(() => {
    const utmCampaign: string | null = searchParams.get("utm_campaign");
    const utmTerm: string | null = searchParams.get("utm_term");
    const utmMedium: string | null = searchParams.get("utm_medium");
    const utmSource: string | null = searchParams.get("utm_source");
    if (utmCampaign || utmTerm || utmMedium || utmSource) {
      const utmQueryParams: UTMParams = {
        utm_campaign: utmCampaign,
        utm_term: utmTerm,
        utm_medium: utmMedium,
        utm_source: utmSource
      };
      setToSessionStorage("utmQueryParams", utmQueryParams);
    }
  }, [searchParams]);
};
export default useStoreUTMQuery;