import { getUserId } from "@common/utils/auth"
import { getApiServerBaseUrl } from "@common/utils/env"
import { ApiRequest, getAPIHeaders } from "@common/utils/service"
import { isUndefinedOrNull } from "@common/utils/variable"

export const fetchPageSummaryAPI = async (): Promise<any> => {
	const headers = getAPIHeaders(true, true)
	return ApiRequest.get(
		`${getApiServerBaseUrl()}/pageSummary?userId=${getUserId()}`,
		headers,
	)
}

export const fetchPlatformDataAPI = () => {
	const headers = getAPIHeaders(true, false)
	return ApiRequest.get(`${getApiServerBaseUrl()}/platform-data`, headers)
}

export const getAllProducts = (status?: string) => {
	const userId = getUserId()
	const isLoggedIn = !isUndefinedOrNull(userId)
	const requestParams = isLoggedIn ? { userId, status } : {}

	const headers = getAPIHeaders(true, isLoggedIn)

	return ApiRequest.get(
		`${getApiServerBaseUrl()}/products/all`,
		headers,
		requestParams,
	)
}

// kyc user details-summary
export const getKycDetailsSummaryAPI = (userId: any) => {
	const headers = getAPIHeaders(true, true)
	return ApiRequest.get(
		`${getApiServerBaseUrl()}/kyc/details-summary?userId=${userId}&product=OBP`,
		headers,
	)
}

export const getPlaformDataAPI = () => {
	const headers = getAPIHeaders(false, false)
	return ApiRequest.get(`${getApiServerBaseUrl()}/platform-data`, headers)
}
