"use client";

import { USER_LOCAL_STORAGE_KEYS, ANALYTICS_KEYS_EVENTS } from "@common/constants/storage_keys";
import { USER_INVESTOR_STATUS } from "@common/constants/user";
import { useAppContext } from "@common/context/AppContext";
import { usePlatformDataContext } from "@common/context/PlatformDataContext";
import { fetchPageSummaryAPI, fetchPlatformDataAPI } from "@common/services/user";
import { User } from "@common/types/user";
import { getFromSessionStorage, isBrowser, setToLocalStorage, setToSessionStorage } from "@common/utils/browser/index";
import useStoreUTMQuery from "@common/hooks/useStoreUTMQuery";
import useAnalytics from "@common/hooks/useAnalytics";
import { usePathname } from "next/navigation";
import { useEffect, ReactNode, useCallback } from "react";
import { getUser } from "@common/utils/auth";
import { trackEvent } from "@common/utils/analytics/gtm";
interface RootTemplateProps {
  children: ReactNode;
}
const RootTemplate: React.FC<RootTemplateProps> = ({
  children
}) => {
  const pathname = usePathname();
  const {
    setIsPageSummaryApiLoading,
    setUserState,
    userState,
    setUserFeatureFlags,
    clevertapModule
  } = useAppContext();
  const {
    setPlatformData,
    setPlatformDataObj
  } = usePlatformDataContext();
  const {
    handleAnalyticsEvent
  } = useAnalytics();
  useStoreUTMQuery();
  const fetchPlatformData = useCallback(async () => {
    const platformData = await fetchPlatformDataAPI();
    const {
      totalAmountOfBondsSold,
      totalAmountOfPrincipalRepaid,
      totalInvestors,
      totalAmountOfInterestRepaidBonds,
      totalInvestorsWithAtleastOneRepayment
    } = platformData || {};
    setPlatformData(platformData);
    setPlatformDataObj({
      totalAmountOfBondsSold: {
        value: totalAmountOfBondsSold
      },
      totalAmountOfPrincipalRepaid: {
        value: totalAmountOfInterestRepaidBonds
      },
      totalInvestors: {
        value: totalInvestors
      },
      totalInvestorsWithAtleastOneRepayment: {
        value: totalInvestorsWithAtleastOneRepayment
      },
      totalRepaidBonds: {
        value: (totalAmountOfInterestRepaidBonds || 0) + (totalAmountOfPrincipalRepaid || 0)
      }
    });
  }, [setPlatformData, setPlatformDataObj]);
  const fetchPageSummary = useCallback(async () => {
    try {
      const pageSummaryData = await fetchPageSummaryAPI();
      const {
        investorStatus,
        isSgbInvestor,
        fdInvestorStatus,
        kycStatus,
        userRfqKycInfo,
        featureFlags,
        sebiNomineeStatus,
        marketData,
        referralData,
        userDebarmentDetails,
        userInvestmentSummary
      } = pageSummaryData;
      setUserState(prevUserState => {
        const updatedState: User = {
          ...prevUserState,
          isBondsInvestor: investorStatus === USER_INVESTOR_STATUS,
          isSgbInvestor,
          isFDInvestor: fdInvestorStatus === USER_INVESTOR_STATUS,
          kycStatusV1: kycStatus,
          kycStatusV2: userRfqKycInfo?.kycStatus,
          featureFlags,
          sebiNomineeStatus,
          marketData,
          referralData,
          userDebarmentDetails,
          userInvestmentSummary
        };
        return updatedState;
      });
      setUserFeatureFlags(featureFlags);
      setToLocalStorage(USER_LOCAL_STORAGE_KEYS.KYC.KYC_STATUS, kycStatus);
      setToLocalStorage(USER_LOCAL_STORAGE_KEYS.KYC.SEBI_KYC_STATUS, userRfqKycInfo?.kycStatus);
      setToLocalStorage(USER_LOCAL_STORAGE_KEYS.BONDS_INVESTOR_STATUS, investorStatus === USER_INVESTOR_STATUS);
      setToLocalStorage(USER_LOCAL_STORAGE_KEYS.IS_SGB_INVESTOR, isSgbInvestor);
      setToLocalStorage(USER_LOCAL_STORAGE_KEYS.FD_INVESTOR_STATUS, fdInvestorStatus === USER_INVESTOR_STATUS);
      setIsPageSummaryApiLoading(false);
    } catch (error) {
      console.error("Error fetching page summary", error);
      setIsPageSummaryApiLoading(false);
    }
  }, [setIsPageSummaryApiLoading, setUserFeatureFlags, setUserState]);
  useEffect(() => {
    if (userState.isLoggedIn) {
      fetchPageSummary();
    }
  }, [fetchPageSummary, userState.isLoggedIn]);
  useEffect(() => {
    fetchPlatformData();
  }, [fetchPlatformData]);
  useEffect(() => {
    const handlePageExitEvent = () => {
      if (window.mixpanel && clevertapModule) {
        handleAnalyticsEvent("Page Exit", {
          "Exited Domain": window.location ? window.location.host : "",
          "Exited URL": window.location ? `${window.location.origin}${pathname}` : "",
          "Exited Path": pathname,
          "Page Domain": window.location ? window.location.host : ""
        });
      }
    };
    if (window.mixpanel && clevertapModule) {
      handleAnalyticsEvent("Page View", {
        "Page Path": pathname
      });
    }
    window.addEventListener("beforeunload", handlePageExitEvent);
    return () => {
      handlePageExitEvent();
      window.removeEventListener("beforeunload", handlePageExitEvent);
    };
  }, [pathname, clevertapModule]);
  useEffect(() => {
    const setPathNameonPageLeave = () => {
      setToSessionStorage("PREVIOUS_PATH", pathname);
    };
    window.addEventListener("beforeunload", setPathNameonPageLeave);
    return () => {
      setPathNameonPageLeave();
      window.removeEventListener("beforeunload", setPathNameonPageLeave);
    };
  }, []);

  //  To stop the screen scaling in iphone when user clicks in input
  useEffect(() => {
    const {
      userAgent
    } = window.navigator;
    if (userAgent?.indexOf("iPhone") || userAgent?.indexOf("wint-ios") > -1) {
      document.querySelector("[name=viewport]")?.setAttribute("content", "minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=yes, user-scalable=no, viewport-fit=cover, interactive-widget=resizes-content");
    }
  }, []);
  useEffect(() => {
    if (!getFromSessionStorage(ANALYTICS_KEYS_EVENTS.SESSION_START)) {
      trackEvent("Session start");
      setToSessionStorage(ANALYTICS_KEYS_EVENTS.SESSION_START, "session_start");
    }
  }, []);
  useEffect(() => {
    if (isBrowser() && window.mixpanel && userState?.isLoggedIn) {
      window.mixpanel.identify(getUser().userId);
    }
  }, [userState?.isLoggedIn]);
  return children;
};
export default RootTemplate;