/* eslint-disable no-param-reassign */

import { SSO_API_SOURCE_FOR_REPO } from "@common/constants"
import { handleApiError } from "../error"
import {
	getFromSessionStorage,
	removeFromSessionStorage,
	isBrowser,
} from "../browser"
import { getUserAuthToken } from "../auth"
import { invariant, isString } from "../variable"
import { pushMeasurementWithFaro } from "../analytics"

/* eslint no-underscore-dangle: 0 */
export function getGAClientId(): string {
	const cookie: { [key: string]: string } = {}

	if (!isBrowser()) return ""

	document.cookie.split(";").forEach(el => {
		const [key, value] = el.split("=").map(item => item.trim())
		if (key && value) {
			cookie[key] = value
		}
	})

	if ("_ga" in cookie && typeof cookie?._ga === "string") {
		return cookie?._ga.substring(6)
	}

	return ""
}

export function getGA4SessionId() {
	try {
		// GA4 session ID is stored in _ga_[MEASUREMENT_ID] cookie
		const cookies = document.cookie.split(";")
		const ga4Cookie = cookies
			.map(cookie => cookie.trim())
			.find(cookie => cookie.indexOf("_ga_") === 0)

		if (ga4Cookie) {
			// GA4 cookie format: sessionNumber.timestamp.sessionId
			const values = ga4Cookie.split(".")
			if (values.length >= 3) {
				return values[2] // This is the session ID
			}
		}

		return ""
	} catch (error) {
		console.error("Error getting GA4 session ID:", error)
		return ""
	}
}

async function handleResponse(response: Response): Promise<any> {
	const data = await response.json()

	if (!response.ok) {
		return handleApiError(data, response.status)
	}

	return data
}

function getUrlWithSearchParams(
	urlString: string,
	searchParams: Record<string, string>,
): string {
	if (!searchParams) return urlString

	try {
		const url = new URL(urlString)

		Object.keys(searchParams).forEach(param => {
			url.searchParams.append(param, searchParams[param])
		})

		return url.toString()
	} catch (error) {
		console.error(error)

		return urlString
	}
}

/**
 * Get API headers based on flags.
 * @param jsonFlag - Whether to include "Content-Type: application/json" header.
 * @param authTokenFlag - Whether to include "X-AUTH-TOKEN" header with user auth token.
 * @param multipartFormData - Whether to include "Content-Type: multipart/form-data" header.
 * @returns The API headers object.
 */
type ApiHeaders = (
	jsonFlag?: boolean,
	authTokenFlag?: boolean,
	multipartFormData?: boolean,
) => Record<string, string>

export const getAPIHeaders: ApiHeaders = (
	jsonFlag = true,
	authTokenFlag = true,
	multipartFormData = false,
) => {
	const headers: Record<string, string> = {}

	if (jsonFlag) {
		headers["Content-Type"] = "application/json"
	} else if (multipartFormData) {
		headers["Content-Type"] = "multipart/form-data"
	}
	headers["client-id"] = getGAClientId()
	headers["ga-session-id"] = getGA4SessionId()

	if (authTokenFlag) {
		const authToken = getUserAuthToken()
		invariant(authToken, "User is not logged in but auth flag set to true")

		// asserting token as non null because of the block above
		headers["X-AUTH-TOKEN"] = authToken!

		if (getFromSessionStorage("utmQueryParams")) {
			const parsedUtmParams: any = getFromSessionStorage("utmQueryParams")

			headers["utm-source"] =
				parsedUtmParams.utm_source === undefined
					? null
					: parsedUtmParams.utm_source
			headers["utm-campaign"] =
				parsedUtmParams.utm_campaign === undefined
					? null
					: parsedUtmParams.utm_campaign
			headers["utm-medium"] =
				parsedUtmParams.utm_medium === undefined
					? null
					: parsedUtmParams.utm_medium
			headers["utm-term"] =
				parsedUtmParams.utm_term === undefined ? null : parsedUtmParams.utm_term
		}

		removeFromSessionStorage("utmQueryParams")
	}

	return headers
}

export const getAPIHeadersWithSource: ApiHeaders = (
	jsonFlag = true,
	authTokenFlag = true,
	multipartFormData = false,
) => {
	const headersWithSource: Record<string, string> = {
		...getAPIHeaders(jsonFlag, authTokenFlag, multipartFormData),
		source: SSO_API_SOURCE_FOR_REPO,
	}
	return headersWithSource
}

async function get(
	url: string | null | undefined,
	headers?: HeadersInit,
	requestParams?: Record<string, any>,
): Promise<any> {
	invariant(isString(url), `URL (${url}) is not string`)

	const requestOptions: RequestInit = {
		method: "GET",
		headers,
	}

	if (requestParams) {
		url = getUrlWithSearchParams(url, requestParams)
	}

	try {
		let apiExecStart: number = 0
		let apiExecStop: number = 0

		apiExecStart = performance.now()

		const data = await fetch(url, requestOptions)

		apiExecStop = performance.now()

		console.debug(
			"\n",
			url,
			"\ntime taken in ms ",
			Number(apiExecStop - apiExecStart).toFixed(0),
			"\n",
		)

		pushMeasurementWithFaro({
			type: `api-url-${url}`,
			values: {
				time_taken: Number(apiExecStop - apiExecStart),
			},
			timestamp: "",
		})

		return await handleResponse(data)
	} catch (error) {
		console.error(`API ${url} error: `, error)
		throw error
	}
}

async function post(
	url: string,
	body: any,
	headers: HeadersInit = {},
	requestParams?: Record<string, any>,
): Promise<any> {
	const requestOptions: RequestInit = {
		method: "POST",
		headers: { ...headers, ...{ "Content-Type": "application/json" } },
		body: JSON.stringify(body),
	}

	if (requestParams) {
		url = getUrlWithSearchParams(url, requestParams)
	}

	try {
		let apiExecStart: number = 0
		let apiExecStop: number = 0

		apiExecStart = performance.now()

		const response = await fetch(url, requestOptions)

		apiExecStop = performance.now()
		console.debug(
			"\n",
			url,
			"\ntime taken in ms ",
			Number(apiExecStop - apiExecStart).toFixed(0),
			"\n",
		)

		pushMeasurementWithFaro({
			type: `api-url-${url}`,
			values: {
				time_taken: Number(apiExecStop - apiExecStart),
			},
			timestamp: "",
		})

		return await handleResponse(response)
	} catch (error) {
		console.error(`API ${url} error: `, error)
		throw error
	}
}

async function put(
	url: string,
	body: any,
	headers: HeadersInit = {},
	requestParams?: Record<string, any>,
): Promise<any> {
	const requestOptions: RequestInit = {
		method: "PUT",
		headers: { ...headers, ...{ "Content-Type": "application/json" } },
		body: JSON.stringify(body),
	}

	if (requestParams) {
		url = getUrlWithSearchParams(url, requestParams)
	}

	try {
		let apiExecStart: number = 0
		let apiExecStop: number = 0
		apiExecStart = performance.now()

		const response = await fetch(url, requestOptions)

		apiExecStop = performance.now()
		console.debug(
			"\n",
			url,
			"\ntime taken in ms ",
			Number(apiExecStop - apiExecStart).toFixed(0),
			"\n",
		)

		pushMeasurementWithFaro({
			type: `api-url-${url}`,
			values: {
				time_taken: Number(apiExecStop - apiExecStart),
			},
			timestamp: "",
		})

		return await handleResponse(response)
	} catch (error) {
		console.error(`API ${url} error: `, error)
		throw error
	}
}

async function patch(
	url: string,
	body: any,
	headers: HeadersInit = {},
	requestParams?: Record<string, any>,
): Promise<any> {
	const requestOptions: RequestInit = {
		method: "PATCH",
		headers: { ...headers, ...{ "Content-Type": "application/json" } },
		body: JSON.stringify(body),
	}

	if (requestParams) {
		url = getUrlWithSearchParams(url, requestParams)
	}

	try {
		let apiExecStart: number = 0
		let apiExecStop: number = 0
		apiExecStart = performance.now()

		const response = await fetch(url, requestOptions)

		apiExecStop = performance.now()
		console.debug(
			"\n",
			url,
			"\ntime taken in ms ",
			Number(apiExecStop - apiExecStart).toFixed(0),
			"\n",
		)

		pushMeasurementWithFaro({
			type: `api-url-${url}`,
			values: {
				time_taken: Number(apiExecStop - apiExecStart),
			},
			timestamp: "",
		})

		return await handleResponse(response)
	} catch (error) {
		console.error(`API ${url} error: `, error)
		throw error
	}
}

// need to be prefixed with underscored because delete is a reserved word in javascript
// eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
async function _delete(
	url: string,
	requestParams?: Record<string, any>,
	headers: HeadersInit = {},
): Promise<any> {
	const requestOptions: RequestInit = {
		method: "DELETE",
		headers,
	}

	if (requestParams) {
		url = getUrlWithSearchParams(url, requestParams)
	}

	try {
		let apiExecStart: number = 0
		let apiExecStop: number = 0
		apiExecStart = performance.now()

		const response = await fetch(url, requestOptions)

		apiExecStop = performance.now()
		console.debug(
			"\n",
			url,
			"\ntime taken in ms ",
			Number(apiExecStop - apiExecStart).toFixed(0),
			"\n",
		)

		pushMeasurementWithFaro({
			type: `api-url-${url}`,
			values: {
				time_taken: Number(apiExecStop - apiExecStart),
			},
			timestamp: "",
		})

		return await handleResponse(response)
	} catch (error) {
		console.error(`API ${url} error: `, error)
		throw error
	}
}

export const ApiRequest = {
	get,
	post,
	put,
	patch,
	delete: _delete,
}
