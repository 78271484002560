import {
	MeasurementEvent,
	PushMeasurementOptions,
	faro,
} from "@grafana/faro-web-sdk"
import {
	getIsUserBondsInvestor,
	getIsUserFDInvestor,
	getUser,
	getUserId,
} from "../auth"
import { assertBrowserEnv, isBrowser, isWebview } from "../browser"

declare global {
	interface Window {
		gtmDataLayer: any[]
		Android: any
		webkit: any
	}
}

export const trackEventOnGTM = (
	eventName: string,
	eventAttributes: any,
): void => {
	try {
		assertBrowserEnv()

		const modifiedEventAttributes = {
			userId: getUserId(),
			...eventAttributes,
		}

		if (isWebview()) {
			modifiedEventAttributes.userAgent = "android"
		}

		window.gtmDataLayer.push({
			event: eventName,
			...modifiedEventAttributes,
		})
	} catch (error) {
		console.error("Error while tracking event on GTM", error)
	}
}

export const getDefaultEventAttributes = (): Record<string, any> => {
	const isBrowserWindow = isBrowser()

	const user = getUser()

	return Object.fromEntries(
		Object.entries({
			"User ID": user.userId,
			IS_FD_INVESTOR: getIsUserFDInvestor(),
			IS_BOND_INVESTOR: getIsUserBondsInvestor(),
			"User Agent": (isBrowserWindow && window.navigator.userAgent) || "",
			IS_FD_WAITLISTER: !!user.isUserFDWaitlister,
			CURRENT_URL: isBrowserWindow ? window.location.href : "",
		}),
	)
}

declare global {
	interface Window {
		mixpanel: any
	}
}

export const handleMixpanelEvent = (
	eventName: string,
	attributes: Record<string, any> = {},
): void => {
	const isBrowserWindow = isBrowser()

	try {
		if (isBrowserWindow && window.mixpanel) {
			window.mixpanel.track(eventName, {
				...attributes,
				...getDefaultEventAttributes(),
			})

			if (eventName === "Page View") {
				window.mixpanel.time_event("Page Exit")
			}
		}
	} catch (e) {
		console.error("Error while tracking event on Mixpanel", eventName, e)
	}
}

export const pushEventWithFaro = (eventName: string, data: any) => {
	// invariant(faro.api, "Faro API is not available")
	if (!faro.api) return

	faro.api.pushEvent(eventName, data)
}

export const pushMeasurementWithFaro = (
	payload: MeasurementEvent,
	options?: PushMeasurementOptions,
) => {
	// invariant(faro.api, "Faro API is not available")
	if (!faro.api) return

	faro.api.pushMeasurement(payload, options)
}
